import { lazy } from "react";
const BasicLayout = lazy(() => import("@containers/BasicLayout")); 
const Home = lazy(() => import("@containers/Home"));
const Search = lazy(() => import("@containers/Search"));
const Tag = lazy(() => import("@containers/Tag"));
const TagCreate = lazy(() => import("@containers/TagCreate/Create"));
const TagNoExist = lazy(() => import("@containers/TagCreate/tagNoExist"));

// index 不能设置子路由
let routes = [
  {
    path: "/",
    element: <BasicLayout />,
    children: [
      { index: true, element: <Home /> },
      {
        path: "/search",
        element: <Search />,
      },
      {
        path: '/tag',
        element: <Tag />
      },
      {
        path: '/tag_create',
        element: <TagCreate/>,
      },
      {
        path: '/tag_no_exist',
        element: <TagNoExist/>,
      },
      { 
        path: "*", 
        element: <Home />
      }
    ],
  }
];

export default routes;