import React,{Suspense} from 'react';
import { useRoutes } from 'react-router-dom'//新增
import router from '@router/router';
import { ConfigProvider,Spin } from 'antd';
import locale from 'antd/locale/zh_CN';

function App() {
  let element = useRoutes(router)//新增
  return (
    // 更改antd主题色
    <ConfigProvider locale={locale} theme={{
      token: {
        colorPrimary: '#22986E',
      },
    }}>
      <Suspense fallback={<Spin />}>
        <div className='App'>
        {element}
        </div>
      </Suspense>
    </ConfigProvider>

  );
}

export default App;
